import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Ohm's Law Calculator",
    link: "/ohms-law-calculator/",
  },
]
const seeAlsoArray = [
  "/boyles-law-calculator",
  "/charles-law-calculator",
  "/density-calculator",
  "/percentage-calculator",
  "/specific-heat-calculator",
]

function OhmsLawCalculator(props) {
  let [Errors, setErrors] = useState("")
  let ohms = useRef("")
  let amps = useRef("")
  let volts = useRef("")

  function Calc() {
    let ohm = ohms.current.value
    let amp = amps.current.value
    let volt = volts.current.value

    if (isNaN(ohm) && isNaN(amp)) {
      setErrors(<strong>Please Enter 2 values to get the third value</strong>)
    } else if (isNaN(ohm) && isNaN(volt)) {
      setErrors(<strong>Please Enter 2 values to get the third value</strong>)
    } else if (isNaN(amp) && isNaN(volt)) {
      setErrors(<strong>Please Enter 2 values to get the third value</strong>)
    } else if ((amp === "" && volt === "") || (amp <= 0 && volt <= 0)) {
      setErrors(<strong>Please Enter 2 values to get the third value</strong>)
    } else if ((ohm === "" && amp === "") || (amp <= 0 && ohm <= 0)) {
      setErrors(<strong>Please Enter 2 values to get the third value</strong>)
    } else if ((volt === "" && ohm === "") || (ohm <= 0 && volt <= 0)) {
      setErrors(<strong>Please Enter 2 values to get the third value</strong>)
    } else if (isNaN(ohm) && isNaN(amp) && isNaN(volt)) {
      setErrors(<strong>Please Enter 2 values to get the third value</strong>)
    } else if (ohm === "" && amp === "" && volt === "") {
      setErrors(<strong>Please Enter 2 values to get the third value</strong>)
    } else if (ohm >= 1 && amp >= 1 && volt >= 1) {
      setErrors(<strong>Please Enter 2 values to get the third value</strong>)
    } else if (ohm === 0 || ohm === "") {
      ohms.current.value = volt / amp + (volt % amp)
      setErrors("")
    } else if (amp === 0 || amp === "") {
      amps.current.value = volt / ohm + (volt % ohm)
      setErrors("")
    } else if (volt === 0 || volt === "") {
      volts.current.value = ohm * amp
      setErrors("")
    }
  }

  function Reset() {
    ohms.current.value = ""
    amps.current.value = ""
    volts.current.value = ""
    setErrors("")
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Ohm's Law Calculator"
        description="Ohm's Law calculates the relation betwixt Voltage, current, and resistance enters a minimum of 2 values to get the third value."
        keywords={[
          "ohm's Law, calculate ohms law, ohm's law calculation, calculate voltage current and resistance, calculate voltage, calculate current, calculate resistance.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>&#9883; Ohm's Law Calculator</Title>

        <SubTitle>
          Ohm's Law calculates the relation betwixt Voltage, Current and
          Resistance.
          <br /> Enter a minimum of 2 values to get the third value.
        </SubTitle>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Resistance (R)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  ref={ohms}
                  placeholder="Resistance"
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  Ohms (Ω)
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Current (I)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  ref={amps}
                  placeholder="Ampere"
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  Amps (A)
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Voltage (V)</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  ref={volts}
                  placeholder="Voltage"
                />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  Volts (V)
                </StaticButton>
              </Control>
            </Field>

            <div className="mb-1">{Errors}</div>

            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              type="button"
              onClick={Calc}
            >
              Calculate
            </Button>
            <Button
              borderColor="#ea4336"
              color="#ea4336"
              hoverBorderColor="#ea4336"
              hoverBackgroundColor="#ea4336"
              hoverColor="white"
              type="button"
              onClick={Reset}
            >
              Reset
            </Button>
          </Column>
          <Column>
            <FlexDiv maxWidth="300px" margin="auto">
              <SmallImg filename="ohms.png" alt="ohms law calculator" />
            </FlexDiv>
          </Column>
        </Columns>

        <br />

        <h3>Ohm's Law</h3>
        <p>
          Ohm's law affirms that the current through a conductor betwixt two
          points are directly proportional to the voltage crossed the two
          points.
          <br />
          The voltage in volts (V) is equal to the current (I) in amps (A) times
          the resistance R in ohms (Ω):
          <br />
          <strong> V = I x R </strong>
          <br />
          <strong>R = V / I </strong>
          <br />
          <strong> I = V / R</strong>
          <br />
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default OhmsLawCalculator
